<!--
 * @Author: a3802 253092329@qq.com
 * @Date: 2024-08-23 20:16:20
 * @LastEditors: a3802 253092329@qq.com
 * @LastEditTime: 2024-08-25 02:18:07
 * @FilePath: \duanju\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
}
</script>
